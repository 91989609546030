<template>
    <div class="budget-settings-icons">
        <styled-tooltip
            position="top"
            class="ml-auto action-icon">
            <template #content>
                View settings in Business Manager
            </template>
            <a
                target="_blank"
                :href="openAdAccountSettings(item) ?? '#'">
                <icon
                    name="cog"
                    size="15" />
            </a>
        </styled-tooltip>
        <styled-tooltip
            position="top"
            class="ml-1 action-icon">
            <template #content>
                View payment settings &amp; spend limits
            </template>
            <a
                target="_blank"
                :href="openAdAccountBilling(item) ?? '#'">
                <icon
                    name="money-sack"
                    size="15" />
            </a>
        </styled-tooltip>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import StyledTooltip from '@/components/globals/StyledTooltip';
import { getPlatformAdAccountBillingUrl, getPlatformAdAccountSettingsUrl } from '@/helpers/platformUrls';

export default {
    components: {
        Icon,
        StyledTooltip
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        platform: {
            type: String,
            required: true
        }
    },
    methods: {
        openAdAccountSettings(item) {
            if (item?.businessManager?.external_business_manager_id && item?.adAccount?.external_ad_account_id) {
                return getPlatformAdAccountSettingsUrl(
                    this.platform,
                    item.businessManager.external_business_manager_id,
                    item.adAccount.external_ad_account_id,
                );
            }
            return null;
        },
        openAdAccountBilling(item) {
            if (item?.businessManager?.external_business_manager_id && item?.adAccount?.external_ad_account_id) {
                return getPlatformAdAccountBillingUrl(
                    this.platform,
                    item.businessManager.external_business_manager_id,
                    item.adAccount.external_ad_account_id,
                );
            }
            return null;
        }
    }
};
</script>

<style lang="scss" scoped>
.budget-settings-icons {
    display: flex;
}
</style>
