import {
    PLATFORM_FACEBOOK,
    PLATFORM_PINTEREST,
    PLATFORM_SNAPCHAT,
    PLATFORM_TIKTOK
} from './globals'

export const URL_TYPE_CAMPAIGN_EDIT = 'campaign_edit';
export const URL_TYPE_CAMPAIGN_ADSETS = 'campaign_adsets';
export const URL_TYPE_ADSET_EDIT = 'adset_edit';
export const URL_TYPE_ADSET_ADS = 'adset_ads';
export const URL_TYPE_AD_EDIT = 'ad_edit';

/**
 * Retrieves deep links for the Facebook platform
 *
 * @param {Object} config
 * @returns {Object}
 */
export const getFacebookAdManagerUrls = ({
    adAccountId,
    businessManagerId,
    objectIds,
}) => {
    return {
        [URL_TYPE_CAMPAIGN_EDIT]: `https://business.facebook.com/adsmanager/manage/campaigns/edit?act=${adAccountId}&business_id=${businessManagerId}&selected_campaign_ids=${objectIds.join(',')}`,
        [URL_TYPE_CAMPAIGN_ADSETS]: `https://business.facebook.com/adsmanager/manage/adsets?act=${adAccountId}&business_id=${businessManagerId}&selected_campaign_ids=${objectIds.join(',')}`,
        [URL_TYPE_ADSET_EDIT]: `https://business.facebook.com/adsmanager/manage/adsets/edit?act=${adAccountId}&business_id=${businessManagerId}&selected_adset_ids=${objectIds.join(',')}`,
        [URL_TYPE_ADSET_ADS]: `https://business.facebook.com/adsmanager/manage/adsets?act=${adAccountId}&business_id=${businessManagerId}&selected_adset_ids=${objectIds.join(',')}`,
        [URL_TYPE_AD_EDIT]: `https://business.facebook.com/adsmanager/manage/ads/edit?act=${adAccountId}&business_id=${businessManagerId}&selected_ad_ids=${objectIds.join(',')}`
    };
};

/**
 * Retrieves deep links for the Snapchat platform
 *
 * @param {Object} config
 * @returns {Object}
 */
export const getSnapchatAdManagerUrls = ({
    adAccountId,
    businessManagerId,
    objectIds,
}) => {
    // @todo Determine if we can dynamically generate Snapchat links.
    //       at the moment they use a token system (8/30)
    return {
        [URL_TYPE_CAMPAIGN_EDIT]: `https://ads.snapchat.com/${adAccountId}/campaigns/${objectIds?.[0]}`,
        [URL_TYPE_CAMPAIGN_ADSETS]: `https://ads.snapchat.com/${adAccountId}/manage`,
        [URL_TYPE_ADSET_EDIT]: `https://ads.snapchat.com/${adAccountId}/adsets/${objectIds?.[0]}`,
        [URL_TYPE_ADSET_ADS]: `https://ads.snapchat.com/${adAccountId}/manage`,
        [URL_TYPE_AD_EDIT]: `https://ads.snapchat.com/${adAccountId}/ads/${objectIds?.[0]}&ad_id=${objectIds?.[0]}`
    };
};

/**
 * Retrieves deep links for the TikTok platform
 *
 * @param {Object} config
 * @returns {Object}
 */
export const getTikTokAdManagerUrls = ({
    adAccountId,
    businessManagerId,
    objectIds,
}) => {
    return {
        [URL_TYPE_CAMPAIGN_EDIT]: `https://ads.tiktok.com/i18n/perf?aadvid=${adAccountId}`,
        [URL_TYPE_CAMPAIGN_ADSETS]: `https://ads.tiktok.com/i18n/perf?aadvid=${adAccountId}`,
        [URL_TYPE_ADSET_EDIT]: `https://ads.tiktok.com/i18n/perf?aadvid=${adAccountId}`,
        [URL_TYPE_ADSET_ADS]: `https://ads.tiktok.com/i18n/perf?aadvid=${adAccountId}`,
        [URL_TYPE_AD_EDIT]: `https://ads.tiktok.com/i18n/perf?aadvid=${adAccountId}`
    };
};

/**
 * Retrieves deep links for the Pinterest platform
 *
 * @param {Object} config
 * @returns {Object}
 */
export const getPinterestAdmManagerUrls = ({
                                           adAccountId,
                                           objectIds,
                                       }) => {
    return {
        [URL_TYPE_CAMPAIGN_EDIT]: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/campaigns/?campaignIds=[${objectIds?.[0]}]`,
        [URL_TYPE_CAMPAIGN_ADSETS]: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/campaigns/?campaignIds=[${objectIds?.[0]}]`,
        [URL_TYPE_ADSET_EDIT]: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/adgroups/?adGroupIds=[${objectIds?.[0]}]`,
        [URL_TYPE_ADSET_ADS]: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/adgroups/?adGroupIds=[${objectIds?.[0]}]`,
        [URL_TYPE_AD_EDIT]: `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/ads/?pinPromotionId=[${objectIds?.[0]}]`,
    };
};

export const getPlatformAdManagerObjectUrl = ({
    adAccountId,
    businessManagerId,
    type,
    objectIds,
    platform
}) => {
    switch (platform) {
        case PLATFORM_FACEBOOK:
            return getFacebookAdManagerUrls({ adAccountId, businessManagerId, objectIds })[type];
        case PLATFORM_SNAPCHAT:
            return getSnapchatAdManagerUrls({ adAccountId, businessManagerId, objectIds })[type];
        case PLATFORM_TIKTOK:
            return getTikTokAdManagerUrls({ adAccountId, businessManagerId, objectIds })[type];
        case PLATFORM_PINTEREST:
            return getPinterestAdmManagerUrls({ adAccountId, objectIds })[type];
    }

}

export const getPlatformAdAccountUrl = (platform, organizationId, adAccountId) => {
    switch (platform) {
        case PLATFORM_FACEBOOK:
            return `https://business.facebook.com/adsmanager/manage/campaigns?act=${adAccountId}&business_id=${organizationId}`;
        case PLATFORM_SNAPCHAT:
            return `https://ads.snapchat.com/${adAccountId}/manage`;
        case PLATFORM_PINTEREST:
            return `https://ads.pinterest.com/advertiser/${adAccountId}/reporting/campaigns/`;
        case PLATFORM_TIKTOK:
            return `https://ads.tiktok.com/i18n/perf?aadvid=${adAccountId}`;
    }
}

export const getPlatformAdAccountBillingUrl = (platform, organizationId, adAccountId) => {
    switch (platform) {
        case PLATFORM_FACEBOOK:
            return `https://business.facebook.com/ads/manager/account_settings/account_billing/?act=${adAccountId}&business_id=${organizationId}&page=account_settings&tab=account_billing_settings`;
        case PLATFORM_SNAPCHAT:
            return `https://business.snapchat.com/${organizationId}/settings/ad-accounts/${adAccountId}/members-billing?ref_aid=${adAccountId}`;
        case PLATFORM_PINTEREST:
            return `https://ads.pinterest.com/advertiser/${adAccountId}/billing/`;
        case PLATFORM_TIKTOK:
            return `https://ads.tiktok.com/i18n/account/payment?aadvid=${adAccountId}`;
    }
}

export const getPlatformAdAccountSettingsUrl = (platform, organizationId, adAccountId) => {
    switch (platform) {
        case PLATFORM_FACEBOOK:
            return `https://business.facebook.com/settings/ad-accounts/${adAccountId}?business_id=${organizationId}`;
        case PLATFORM_SNAPCHAT:
            return `https://business.snapchat.com/${organizationId}/settings/ad-accounts/${adAccountId}?ref_aid=${adAccountId}`;
        case PLATFORM_PINTEREST:
            return `https://www.pinterest.com/business/business-access/${organizationId}/ad-accounts/${adAccountId}/details/`;
        case PLATFORM_TIKTOK:
            return `https://ads.tiktok.com/i18n/account/complete?aadvid=${adAccountId}`;
    }
}
